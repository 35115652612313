<!--
 * @Descripttion: 登录页面
 * @version: DBNetGameManagement_v1.0
 * @Author: jerry_huang
 * @Date: 2021-08-30 14:12:28
 * @LastEditTime: 2021-10-09 16:19:28
-->
<template>
  <div>
    <Header />
    <div class="contentDiv">

      <el-form ref="loginForm"
               :model="form"
               :rules="rules"
               class="login-box">
        <h3 class="login-title">欢迎登录</h3>
        <el-form-item prop="username">
          <el-input type="text"
                    prefix-icon="el-icon-user"
                    placeholder="请输入账号或手机号码"
                    v-model="form.username" />
        </el-form-item>
        <el-form-item prop="password">
          <el-input type="password"
                    prefix-icon="el-icon-lock"
                    placeholder="请输入密码"
                    v-model="form.password" />
        </el-form-item>
        <el-form-item v-if="isCaptcha"
                      prop="captcha_code">
          <el-input type="text"
                    style="float:left;margin-right: -100px;"
                    placeholder="请输入验证码"
                    v-model="form.captcha_code" />
          <el-image style="width: 99px;float: left;height: 38px; padding: 1px 1px 1px 0;"
                    :src="captchaImg"></el-image>
        </el-form-item>   
        <el-form-item> 
          <el-checkbox v-model="form.RememberLogin">记住登录状态</el-checkbox>     <router-link  :to="{path:'forgetpwd'}" :underline="false" style="margin-left:50px; font-size: 14px; color: #6e6e6e; text-decoration: auto;" type="info">忘记密码</router-link>
        </el-form-item>
        <el-form-item>
          <el-button :loading="loading"
                     type="primary"
                     v-on:click="onSubmit('loginForm')">登录</el-button>
          <el-button type="warning"
                     v-on:click="goto('register')">注册</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import { getCaptcha, login } from "@/api/login";
import "@/styles/login.css";
import Header from "../../components/Header.vue";
export default {
  components: { Header },

  directives: {},

  data() {
    return {
      captchaImg: "",
      loading: false,
      isCaptcha: false,
     
      form: {
        username: "",
        password: "",
        captcha_code: "",
        captcha_id: "",
         RememberLogin:false,
      },

      // 表单验证，需要在 el-form-item 元素中增加 prop 属性
      rules: {
        username: [
          { required: true, message: "账号不可为空", trigger: "blur" },
        ],
        password: [
          { required: true, message: "密码不可为空", trigger: "blur" },
        ],
        captcha_code: [
          { required: true, message: "验证码不可为空", trigger: "blur" },
        ],
      },

      // 对话框显示和隐藏
      dialogVisible: false,
    };
  },

  mounted() {
    this.getCaptcha();
  },

  methods: {
    goto(url) {
      this.$router.push("/" + url);
    },
    // 登录
    login() {
      login(this.form)
        .then((res) => {
          //console.log(res);
          if (res.code == 200) {
            this.$message({
              showClose: true,
              message: res.msg,
              type: "success",
            });
          } else {
            this.$message.error({ message: res.msg, center: true });
          }
        })
        .catch(() => {
          this.getCaptcha();
          // console.log(1, err);
        });
    },
    // 获取验证码
    getCaptcha() {
      getCaptcha("").then((res) => {
        this.form.captcha_id = res.data.captcha_id;

        this.isCaptcha = res.data.captcha_switch;
        this.captchaImg = res.data.captcha_src;
      });
    },
    onSubmit(formName) {
      // 为表单绑定验证功能
      this.$refs[formName].validate((valid) => {
        this.loading = true;
        if (valid) {
          // 使用 vue-router 路由到指定页面，该方式称之为编程式导航
          //this.login();
          //  console.log(this.form);
          this.$store
            .dispatch("user/login", this.form)
            .then(() => {
              this.$message({
                showClose: true,
                message: "登录成功",
                type: "success",
              });

              this.$router.push({ path: "/member/person/memberinfo" });

              // console.log(res)

              //this.loading = false;
            })
            .catch(() => {
              this.getCaptcha();
              this.loading = false;
            });
        } else {
          this.$message.error({ message: "输入账号和密码", center: true });
          this.loading = false;
          return false;
        }
      });
    },
  },
};
</script> 

<style lang="scss" scoped >
</style> 